import React from "react"

import * as styles from "./index.module.scss"

const Numbers = ({ data }) => {
  return (
    <div className={`${styles.numbersContainer} py-8 md:py-3`}>
      <div className="mx-auto max-w-6xl flex flex-col xl:flex-row justify-between items-center">
        <div className="flex items-center w-10/12 md:w-5/12 lg:w-7/12 mb-8 lg:mb-0">
          <h1 className="text-white">{data.achievementCreationDate}</h1>
          <span className="h2-light ml-2 xl:ml-4 text-white">
            {data.achievementCreationDateTitle}
          </span>
        </div>
        <div className="flex items-center w-10/12 md:w-5/12 lg:w-7/12 mb-8 lg:mb-0">
          <h1 className="text-white">{data.achievementServingEnterprises}</h1>
          <span className="h2-light ml-2 xl:ml-4 text-white">
            {data.achievementServingEnterprisesTitle}
          </span>
        </div>
        <div className="flex items-center w-10/12 md:w-5/12 lg:w-7/12">
          <h1 className="text-white">{data.achievementQuotesGenerated}</h1>
          <span className="h2-light ml-2 xl:ml-4 text-white">
            {data.achievementQuotesGeneratedTitle}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Numbers
