import React from "react"
import { Link } from "gatsby"

import * as styles from "./index.module.scss"

export default function linkButton({ children, className, ...rest }) {
  return (
    <Link {...rest}>
      <div
        className={`${styles.linkButton} ${className} flex items-center justify-center text-white`}
      >
        <span className={`${styles.text} z-10`}>{children}</span>
      </div>
    </Link>
  )
}
