import React from "react"

// Assets and Styles
import * as styles from "./index.module.scss"

const MidBanner = ({ data }) => {
  return (
    <div className={`${styles.midBannerContainer}`}>
      <div className="max-w-6xl mx-auto px-12 md:px-8 xl:px-0 flex flex-col-reverse lg:flex-row items-center py-3">
        <div className="w-full lg:w-4/5 mt-3 md:mt-0 lg:mr-3">
          <h1
            className={`text-white text-center lg:text-left ${styles.bannerHeading}`}
          >
            {data.midBannerTitle}
          </h1>
        </div>
        <div className="w-3/5 lg:w-1/5 flex justify-center">
          <img src={data.midBannerImage.file.url} className="w-full" />
        </div>
      </div>
    </div>
  )
}

export default MidBanner
