import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import { HomeRight } from "../../../components/icons"
import BackgroundImage from "../../../assets/images/B2B-header-tall.svg"

import * as styles from "./index.module.scss"
import { useToggleDemo } from "../../../context/popupContext"

const HeroSection = ({ data }) => {
  const image = getImage(data.bannerImage)
  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="container max-w-6xl py-24 m-auto">
        <div className="grid-cols-2 lg:grid lg:px-6 xl:px-0">
          <div className="md:pt-4">
            <div className={`${styles.heroInfoWrapper}`}>
              <h1 className="text-center text-white uppercase lg:text-left">
                {data.bannerTitle}
              </h1>
              <h2 className="py-6 text-lg font-semibold text-center text-white h2-light lg:text-left">
                {data.bannerSubtitle}
              </h2>
              <button
                className={`${styles.bgBlack} mt-9 py-5 px-9 items-center text-left hidden lg:flex`}
                // href="https://calendly.com/quote-ninja/demo"
                // target="_blank"
                onClick={useToggleDemo()}
              >
                <p className="flex-1 font-bold text-white uppercase button-18pt">
                  {data.bannerButtonText}
                </p>
                <div className={`${styles.IconContainer} flex`}>
                  <HomeRight size={20} pathClass={`${styles.RightIcon}`} />
                  <HomeRight size={20} pathClass={`${styles.RightIcon}`} />
                  <HomeRight size={20} pathClass={`${styles.RightIcon}`} />
                </div>
              </button>
            </div>
          </div>
          <div className="flex justify-center lg:justify-end">
            <div className="w-9/12 md:w-6/12 lg:w-10/12 xl:w-9/12">
              <GatsbyImage
                loading="eager"
                image={image}
                alt={data.bannerImage.title}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
