import React from "react"

const Merchants = ({ data }) => {
  return (
    <div className="hidden md:block py-4">
      <div className="max-w-6xl m-auto flex justify-between px-4 md:px-8 xl:px-0">
        {data.map((img, index) => (
          <div key={index} className="flex justify-center">
            <img
              alt={img.title}
              src={img.file.url}
              loading="lazy"
              className="w-4/5 lg:w-full"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Merchants
