import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./index.module.scss"

const Preffered = ({ data }) => {
  const image = getImage(data.preferredPartnerImage)
  return (
    <div
      className={`${styles.containerBg} lg:px-4 xl:px-0 pt-5 hidden lg:block`}
    >
      <div className="max-w-5xl m-auto flex items-center">
        <div>
          <GatsbyImage
            alt={data.preferredPartnerImage.title}
            image={image}
            loading="lazy"
          />
        </div>
        <div className="pl-14 flex-1 pb-12">
          <h3>{data.preferredPartnerTitle}</h3>
          <p>{data.preferredPartnerDescription.preferredPartnerDescription}</p>
        </div>
      </div>
    </div>
  )
}

export default Preffered
