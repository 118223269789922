import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Stylesheet
import * as styles from "./index.module.scss"

const CaseStudies = ({
  transparent = false,
  heading,
  subHeading,
  data,
  padBot,
  ...props
}) => {
  const { caseStudiesTitle, caseStudiesDescription, caseStudiesList } = data
  return (
    <div
      style={{
        backgroundColor: `${props.altBack ? "#EDEFF3" : "#FFFFFF"}`,
      }}
      className={`${padBot ? "mb-6 lg:mb-32" : ""}`}
    >
      <div className="max-w-6xl m-auto px-0 md:px-4 xl:px-0 py-8">
        <div className="px-8 md:px-0">
          <h3 className="text-center lg:text-left lg:w-10/12">
            {caseStudiesTitle || "Real Case Studies"}
          </h3>
          {caseStudiesDescription && (
            <p className="w-full md:w-2/5 md:mx-auto lg:mx-0 py-2 text-center lg:text-left">
              {caseStudiesDescription}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-12 pt-8">
          {caseStudiesList.map(card => {
            const { id, link, slogan, backgroundImage, logo } = card
            const image = getImage(backgroundImage)
            return (
              <div key={id} className={`${styles.card} rounded`}>
                <GatsbyImage
                  image={image}
                  alt={backgroundImage.title}
                  className={styles.imgHolder}
                />
                <div
                  className={`${styles.cardContent} flex flex-col justify-center items-center md:pb-8 pb-4`}
                >
                  <div className="w-2/5 md:w-2/5">
                    <img src={logo.file.url} alt={logo.title} loading="lazy" />
                  </div>
                  <h2 className="w-10/12 md:w-3/5 my-4 text-center text-white">
                    {slogan}
                  </h2>
                  <div className={`${styles.linkContainer}`}>
                    <Link to={`/${link}`} className="text-gray-100 button-18pt">
                      See Case Study
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CaseStudies
