import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"

import { ChevronRight } from "../icons"
import ReviewBg from "../../assets/images/review.svg"
import * as styles from "./index.module.scss"

const NextButton = ({ onClick }) => {
  return (
    <div>
      <button onClick={onClick} className={`${styles.nextButton}`}>
        <ChevronRight size={18} className={styles.buttonIcon} />
      </button>
    </div>
  )
}

const PrevButton = ({ onClick }) => {
  return (
    <div>
      <button onClick={onClick} className={`${styles.prevButton}`}>
        <ChevronRight className={styles.buttonIcon} size={18} />
      </button>
    </div>
  )
}

const PaginationButton = ({ onClick, page }) => {
  return (
    <button onClick={onClick} className={`${styles.PaginationButton}`}>
      {page}
    </button>
  )
}

const Reviews = ({ titleData: { reviewTitle, reviewSubtitle } }) => {
  const data = useStaticQuery(graphql`
    query reviewQuery {
      reviews: allContentfulReviews(sort: { fields: createdAt }) {
        edges {
          node {
            id
            review
            websiteUrl
            logo {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const reviewData = data.reviews.edges

  const settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 769,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    customPaging: i => <PaginationButton page={`review ${i + 1}`} />,
  }

  return (
    <div
      style={{
        backgroundImage: `url(${ReviewBg})`,
        backgroundSize: "contain",
        backgroundPosition: "top right",
      }}
    >
      <div className="container mx-auto max-w-6xl px-4 xl:px-0 pt-12">
        <h3 className="text-center md:text-left font-bold text-3xl">
          {reviewTitle}
        </h3>
        <p className="text-center md:text-left pt-1 pb-8 w-full md:w-2/5">
          {reviewSubtitle}
        </p>
      </div>
      <div className=" max-w-6xl mx-auto px-4 xl:px-0 pb-14 xl:pb-36">
        <Slider {...settings}>
          {reviewData.map(reviewNode => {
            const { id, review, logo, websiteUrl } = reviewNode.node
            return (
              <div key={id} className={`${styles.reviewSlideContainer}`}>
                <div className={styles.slide}>
                  <div className={styles.logoContainer}>
                    <img src={logo.file.url} alt={logo.title} loading="lazy" />
                  </div>
                  <p className="flex-1 px-6 py-2">{review}</p>
                  <div className={styles.addressContainer}>
                    <p className="uppercase font-bold w-full text-right">
                      {websiteUrl}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default Reviews
