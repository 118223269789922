import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import CaseStudies from "../components/caseStudies"
import Numbers from "../components/numbers"
import Features from "../containers/Home/Features"
import Merchants from "../containers/Home/Merchants"
import Reviews from "../components/reviews"
import HeroSection from "../containers/Home/HeroSection"
import Preffered from "../components/preffered"
import MidBanner from "../containers/Home/MidBanner"

const HomePage = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerButtonText,
    bannerImage,
    caseStudiesDescription,
    caseStudiesTitle,
    caseStudiesList,
    midBannerTitle,
    midBannerImage,
    clients,
    reviewTitle,
    reviewSubtitle,
    featuresDescription,
    featuresList,
    featuresTitle,
    preferredPartnerDescription,
    preferredPartnerImage,
    preferredPartnerTitle,
  } = data.home

  const HeroData = {
    bannerTitle,
    bannerSubtitle,
    bannerButtonText,
    bannerImage,
  }

  const PrefferedData = {
    preferredPartnerTitle,
    preferredPartnerImage,
    preferredPartnerDescription,
  }

  const CaseStudiesData = {
    caseStudiesTitle,
    caseStudiesDescription,
    caseStudiesList,
  }

  const FeaturesData = {
    featuresTitle,
    featuresDescription,
    featuresList,
  }

  const ReviewData = {
    reviewTitle,
    reviewSubtitle,
  }

  const midBannerData = {
    midBannerTitle,
    midBannerImage,
  }

  return (
    <Layout>
      <Seo title={ogTitle || "Home"} description={ogDescription} />
      <HeroSection data={HeroData} />
      <Merchants data={clients} />
      <Preffered data={PrefferedData} />
      <CaseStudies data={CaseStudiesData} />
      <MidBanner data={midBannerData} />
      <Features data={FeaturesData} />
      <Reviews titleData={ReviewData} />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    home: contentfulHomePage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        gatsbyImageData(width: 550, quality: 100, placeholder: BLURRED)
      }
      bannerButtonText
      clients {
        file {
          url
        }
      }
      preferredPartnerTitle
      preferredPartnerDescription {
        preferredPartnerDescription
      }
      preferredPartnerImage {
        title
        gatsbyImageData(width: 135, quality: 100, placeholder: BLURRED)
      }
      caseStudiesTitle
      caseStudiesDescription
      caseStudiesList {
        id
        link
        slogan
        logo {
          title
          file {
            url
          }
        }
        backgroundImage {
          title
          gatsbyImageData(width: 650, placeholder: BLURRED)
        }
      }
      midBannerTitle
      midBannerImage {
        file {
          url
        }
      }
      reviewTitle
      reviewSubtitle
      featuresTitle
      featuresDescription
      featuresList {
        title
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
    }

    about: contentfulAboutPage {
      achievementCreationDate
      achievementCreationDateTitle
      achievementQuotesGenerated
      achievementQuotesGeneratedTitle
      achievementServingEnterprises
      achievementServingEnterprisesTitle
    }
  }
`

export default HomePage
