import React from "react"
import LinkButton from "../../../components/linkButton"

// Stylesheet
import * as styles from "./index.module.scss"

const Features = ({ data }) => {
  return (
    <div className={`${styles.featureContainer}`}>
      <div className="mx-auto max-w-6xl px-12 md:px-8 xl:px-0 md:px-0 py-12">
        <h3 className="text-center lg:text-left lg:w-10/12">
          {data.featuresTitle}
        </h3>
        <p className="w-full md:w-2/5 md:mx-auto lg:mx-0 py-2 text-center lg:text-left pt-2 pb-8">
          {data.featuresDescription}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-12 gap-y-8 mb-8">
          {data.featuresList.map((feature, idx) => {
            const { title, description, image } = feature
            return (
              <div key={idx} className="w-full">
                <img
                  src={image.file.url}
                  alt={image.title}
                  loading="lazy"
                  className={styles.featureImage}
                />
                <h4 className="my-2">{title}</h4>
                <p className="w-full h-full sm:h-20 text-gray-700">
                  {description.description}
                </p>
              </div>
            )
          })}
        </div>
        <LinkButton to="/quotes" className="mt-3 md:mt-12 mx-auto md:mx-0">
          View all Features
        </LinkButton>
      </div>
    </div>
  )
}

export default Features
